@media (max-width: 910px) {
  .image {
    display: none !important;
  }
  .home-stack {
    display: flex !important;
    flex-direction: column !important;
  }
  .welcome-text {
    text-align: center;
    padding: 0;
    margin: 0;
  }
}
