:root {
  --main: #efecca;
  --secondary: #a9cbb7;
  --third: #f7ff58;
  --fourth: #ff934f;
  --fifth: #5e565a;
}

html, body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @media (max-width: 910px) {
  .image {
    position: relative;
  }
  .home-stack {
    display: flex;
    flex-direction: column;
  }
} */
